import React, { Fragment, useContext, useEffect, useState } from "react";
import { Button, Header, Icon, Segment, Table } from "semantic-ui-react";
import { Field, Form as FinalForm } from "react-final-form";
import { Form } from "semantic-ui-react";
import SelectInput from "../../components/layout/form/SelectInput";
import { RootStoreContext } from "../../stores/rootStore";
import { observer } from "mobx-react-lite";
import { IRegisteredUserByCompany } from "../../models/user";

const RegisteredUsers = () => {
  const rootStore = useContext(RootStoreContext);
  const { userRoles } = rootStore.userStore;
  const { companies, GetCompanies, companyUsers } = rootStore.companyStore;
  const { setUserIdToRemove, ResendConfirmationEmail } = rootStore.userStore;
  const [loadingComp, setLoadingComp] = useState(true);
 

  function handleRemoveUser(data: IRegisteredUserByCompany) {
 // note: this methods will be used to open the confirmation
 // modal and set the userId for the user we want to delete
    setUserIdToRemove(data.userId, data.companyKey)
  }

 
  useEffect(() => {
    if (companies.length <= 0) GetCompanies().then(() => setLoadingComp(false));
    else setLoadingComp(false);
  }, [GetCompanies, companies, userRoles]);
  return (
    <Fragment>
      <Header as="h2" attached="top">
        Vew registered users
      </Header>
      <Segment clearing>
        <FinalForm
          onSubmit={() => {}}
          render={({ handleSubmit }) => (
            <Form onSubmit={handleSubmit} error>
              <Form.Group widths="equal">
                <Field
                  label="Company"
                  component={SelectInput}
                  name="company"
                  componentFlag={"registeredUsers"}
                  loading={loadingComp}
                  placeholder="Select a Company"
                  options={companies}
                />
              </Form.Group>
            </Form>
          )}
        />
        {companyUsers.length > 0 && (
          <Table selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Username</Table.HeaderCell>
                <Table.HeaderCell>Email</Table.HeaderCell>
                <Table.HeaderCell>Role</Table.HeaderCell>
                <Table.HeaderCell>Email Confirmed</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {companyUsers.map((e: IRegisteredUserByCompany, i: number) => (
                <Table.Row key={i.toString()}>
                  <Table.Cell>{e.name}</Table.Cell>
                  <Table.Cell>{e.userName}</Table.Cell>
                  <Table.Cell>{e.email}</Table.Cell>
                  <Table.Cell>{e.role}</Table.Cell>
                  <Table.Cell>
                    {e.emailConfirmed === false ? (
                      <div>
                        <Button
                          fluid
                          color="yellow"
                          content="Send New Request"
                          icon="mail"
                          compact
                          onClick={() => ResendConfirmationEmail(e.userId)}
                          labelPosition="left"
                        />
                      </div>
                    ) : (
                      <Icon color="green" name="thumbs up outline" />
                    )}
                  </Table.Cell>

                  <Table.Cell>
                    <Button
                      color="red"
                      compact
                      onClick={() => handleRemoveUser(e)}
                      labelPosition="left"
                      content="Remove"
                      icon="trash"
                    />
                  </Table.Cell>
                </Table.Row>
              ))}{" "}
            </Table.Body>
          </Table>
        )}
      </Segment>
    </Fragment>
  );
};

export default observer(RegisteredUsers);
