import React, {  useContext } from "react";
import { RootStoreContext } from "../stores/rootStore";
import { Container } from "reactstrap";
import { Redirect, Link } from "react-router-dom";
import { Form as FinalForm, Field } from "react-final-form";
import { Grid, Segment, Form, Button } from "semantic-ui-react";
import TextInput from "../components/layout/form/TextInput";
import { FORM_ERROR } from "final-form";
import { combineValidators, isRequired } from "revalidate";
import { ILoginModel } from "../models/loginModel";
import AuthNavMenu from "../components/layout/navBars/AuthNavMenu";
import RegisterErrorMessage from "../components/layout/RegisterErrorMessage";

const validate = combineValidators({
  username: isRequired("username"),
  password: isRequired("password")
});

export default function Login() {
  const rootStore = useContext(RootStoreContext);

  const { Login, user, isLoggedIn } = rootStore.userStore;

  if (isLoggedIn) {
    if (user?.role === 'Admin') return <Redirect to="/dashboard" />;
    else return <Redirect to="/customer-profile" />;
  }
  return (
    <div>
      <div>
        <AuthNavMenu />
        <Container>
          <p className="h3">
            If you have not already been provided with a user account, please
            contact your account manager.
          </p>
          <br />

          <Grid centered>
            <Grid.Column width={6}>
              <Segment clearing>
                <FinalForm
                  onSubmit={(values: ILoginModel) =>
                    Login(values).catch(error => ({
                      [FORM_ERROR]: error
                    }))
                  }
                  validate={validate}
                  render={({
                    handleSubmit,
                    submitting,
                    submitError,
                    invalid,
                    pristine,
                    dirtySinceLastSubmit
                  }) => (
                    <Form onSubmit={handleSubmit} error>
                      <Field
                        label="Username"
                        name="username"
                        placeholder="Username"
                        component={TextInput}
                      />
                      <Field
                        label="Password"
                        icon="lock"
                        name="password"
                        type="password"
                        placeholder="Password"
                        component={TextInput}
                      />
                      {/* to be finished later */}
                      <Link to="/forgot-password">Reset Password</Link>
                      {submitError && !dirtySinceLastSubmit && (
                        // <ErrorMessage
                        //   error={submitError}
                        //   text={"Invalid Username or Password"}
                        // />
                        <RegisterErrorMessage error={submitError}   />
                      )}
                      <br></br>
                      <Button
                        fluid
                        icon="sign in"
                        loading={submitting}
                        disabled={
                          (invalid && !dirtySinceLastSubmit) || pristine
                        }
                        primary
                        content="Login"
                      />
                    </Form>
                  )}
                />
              </Segment>
            </Grid.Column>
          </Grid>
        </Container>
      </div>
    </div>
  );
}
