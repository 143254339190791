import React, { useContext, useEffect } from "react";
import { Tab } from "semantic-ui-react";
import Footer from "../../components/layout/Footer";
import AddCompany from "./addCompany";
import ManageUsers from "./ManageUsers";
import { observer } from "mobx-react-lite";
import AuthNavMenu from "../../components/layout/navBars/AuthNavMenu";
import { RootStoreContext } from "../../stores/rootStore";
import { Redirect } from "react-router-dom";

const Dashboard = () => {
  const rootStore = useContext(RootStoreContext);
  const { user } = rootStore.userStore;



  useEffect(() => {
    document.title += " - Dashboard";
  });

  const panes = [
    {
      menuItem: "Manage Users",
      render: () => (
        <Tab.Pane>
          {/* <RegisterUser /> */}
          <ManageUsers />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Manage Companies",
      render: () => (
        <Tab.Pane>
          <AddCompany />
        </Tab.Pane>
      ),
    },
  ];

 if(user?.role === 'Admin')
  return (
    <div>
      <AuthNavMenu />
      <Tab
        style={{ border: "none" }}
        menu={{
          fluid: true,
          vertical: true,
        }}
        menuPosition="left"
        panes={panes}
      />

      <Footer />
    </div>
  );

  return <Redirect to='/customer-profile' />
 

};

export default observer(Dashboard);
