import { action, runInAction, observable, computed } from "mobx";
import agent from "../API/agent";
import { RootStore } from "./rootStore";
import { ICompany } from "../models/company";
import { ICompanySubmit, ICompanyValues } from "../models/ICompanySubmit";
import { toast } from "react-toastify";
import { history } from "..";
import { IRegisteredUserByCompany } from "../models/user";

export default class CompanyStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }
  @observable companies: ICompany[] = [];
  @observable companyLogo: Blob[] = [];
  @observable submittingData = false;
  @observable delCompanyModal: boolean = false;
  @observable companyId: string = "";
  @observable company: ICompanyValues | null = null;
  @observable companyUsers: IRegisteredUserByCompany[] = [];

  @action SetCompanyPhoto = async (photo: any) => {
    runInAction(() => {
      this.companyLogo = photo;
    });
  };
  @action openDelModal = async (data: ICompany) => {
    const comp = this.companies.find((e) => e.value == data.value);
    if (comp) this.companyId = comp.companyId;
    this.delCompanyModal = true;
  };
  @action closeDelModal = async () => (this.delCompanyModal = false);

  @action find = async (data: string) => {
    try {
      const comp = this.companies.find((e) => e.value === data);
      const result = await agent.company.find(comp?.companyId!);
      if (result) {
        runInAction(() => {
          this.company = result;
        });
      }
    } catch (error) {
      throw error;
    }
  };
  @action editCompany = async (data: ICompanyValues) => {
    try {
      const result = await agent.company.edit(data.companyId!, data);
      if (result) {
        runInAction(() => {
          this.GetCompanies();
          this.company = null;
        });
        toast.success("Company details successfully updated!");
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  @action remove = async () => {
    try {
      this.delCompanyModal = false;
      const result = await agent.company.remove(this.companyId);
      if (result) {
        this.GetCompanies();
      }
    } catch (error) {}
  };
  
  @action GetCompanies = async () => {
    try {
      const localComp = await agent.company.list();
      runInAction(() => {
        this.companies = localComp;
      });
    } catch (err) {
      console.log("[GET COMPANIES ERROR]: ", err);
    }
  };
  @action addNewCompanyData = async (values: ICompanySubmit) => {
    try {
      values.photo = this.companyLogo[0];

      const companyData = await agent.company.addCompanyData(values);
      if (companyData) {
        this.GetCompanies();
        history.push("/dashboard");
        toast.success("Company Details Added Successfully");
      }
    } catch (error) {
      throw error;
    }
  };


  @action refreshUserTable = async (companyKey: string) => {
    try {
  
      const result = await agent.company.loadUserByCompany(companyKey);

      if (result)
        runInAction(() => {
          this.companyUsers = result;
        });
  
    } catch (error) {
      throw error;
    }
  };
  @action loadUsersForCompany = async (data: string) => {
    try {
      const comp = this.companies.find((e) => e.value === data);
      const result = await agent.company.loadUserByCompany(comp?.key!);

      if (result)
        runInAction(() => {
          this.companyUsers = result;
        });
      else {
        this.companyUsers = [];
      }
    } catch (error) {
      throw error;
    }
  };
}
