import { observable, action, computed, runInAction } from "mobx";
import {
  IUser,
  IUserFormValues,
  IPasswordConfirmation,
  IRole,
} from "../models/user";
import agent from "../API/agent";
import { RootStore } from "./rootStore";
import { history } from "..";
import { IRegisterModel } from "../models/registerModel";
import { IResetPassword } from "../models/resetPasswordModel";
import { toast } from "react-toastify";

export default class UserStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }
  @observable user: IUser | null = null;
  @observable userRoles: IRole[] = [];
  @observable delUserModal: boolean = false;
  @observable submitting = false;
  @observable passwordConfirmation: IPasswordConfirmation | null = null;
  @observable userId: string = "";
  @observable companyKey: string = "";
  @observable deletingUserLoading: boolean = false;
  @computed get isLoggedIn() {
    return !!this.user;
  }
  @action loadUserRole = async () => {
    try {
      const data = await agent.roles.list();
      if (data)
        runInAction(() => {
          this.userRoles = data;
        });
    } catch (error) {
      console.log("[loadUserRole]", error);
      throw error;
    }
  };

  @action CloseDelModal = () => {
    this.delUserModal = false;
  };

  @action RequestPassword = async (user: any) => {
    try {
      const response = await agent.User.forgetPassword(user.username);
      if (response) {
        history.push("/");
        toast.info("Please check your email!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      console.log("[FORGET PASSWORD ERROR]: ", error);
    }
  };
  @action Login = async (values: IUserFormValues) => {
    try {
      const result = await agent.User.login(values);
      if (result) {
        runInAction(() => {
          this.user = result;
        });
        this.rootStore.commonStore.setToken(result.token);
        if (this.user?.role === "Admin") history.push("/dashboard");
        else history.push("/customer-profile");
      }
    } catch (error) {
      throw error;
    }
  };

  @action Register = async (values: IRegisterModel) => {
    try {
      const regUser = await agent.User.register(values);
      if (regUser) {
        toast.success("User registered successfully!!");
        history.push("/");
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  @action ResetPassword = async (values: IResetPassword) => {
    try {
      const result = await agent.User.resetPassword(values);
      if (result) {
        toast.info("Please login now");
        history.push("/");
      }
    } catch (error) {
      throw error;
    }
  };
  @action getUser = async () => {
    try {
      const user = await agent.User.current();
      if (user)
        runInAction(() => {
          this.user = user;
        });
    } catch (error) {
      console.log(error);
    }
  };
  @action logout = () => {
    this.rootStore.commonStore.setToken(null);
    this.user = null;
    history.push("/");
  };

  @action setUserIdToRemove = async (userId: string, companyKey: string) => {
    try {
      runInAction(() => {
        this.delUserModal = true;
        this.userId = userId;
        this.companyKey = companyKey;
      });
    } catch (error) {}
  };

  @action RemoveUser = async () => {
    try {
      runInAction(() => {
        this.deletingUserLoading = true;
      });
      const result = await agent.company.removeUser(this.userId);
      if (result) {
        this.rootStore.companyStore.refreshUserTable(this.companyKey);

        runInAction(() => {
          this.deletingUserLoading = false;
        });
        this.CloseDelModal();
        toast.success("User removed successfully!");
      }
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => {
        this.deletingUserLoading = false;
      });
    }
  };

  @action ResendConfirmationEmail = async (userId: string) => {
    console.log(userId);

    try {
      const result = agent.User.resendEmail(userId);

      console.log(result);

      if (result) {
        this.rootStore.modalStore.closeModal();
        toast.info("New confirmation email sent!");
      }
    } catch (error) {
      throw error;
    }
  };
}
