import { FORM_ERROR } from "final-form";
import React, { useContext, useState } from "react";
import { Field, Form as FinalForm } from "react-final-form";
import { combineValidators, isRequired } from "revalidate";
import { Button, Form, Grid, Header, Segment } from "semantic-ui-react";
import { ICompanySubmit, ICompanyValues } from "../../models/ICompanySubmit";
import SelectInput from "../../components/layout/form/SelectInput";
import TextInput from "../../components/layout/form/TextInput";
import { RootStoreContext } from "../../stores/rootStore";
import UploadPhoto from "../../components/admin/photo/uploadPhoto";
import { observer } from "mobx-react-lite";
import RegisterErrorMessage from "../../components/layout/RegisterErrorMessage";
import { ICompany } from "../../models/company";
import { findDOMNode } from "react-dom";

const validate = combineValidators({
  name: isRequired("Company Name"),
  code: isRequired("Company Code"),
  warehousecode: isRequired("Warehouse Code"),
  ftpName: isRequired("FTP Name"),
  ftpPassword: isRequired("FTP Password"),
});
const validateUpdate = combineValidators({
  value: isRequired("Company Name"),
  key: isRequired("Company Code"),
  warehousecode: isRequired("Warehouse Code"),
  ftpName: isRequired("FTP Name"),
  ftpPassword: isRequired("FTP Password"),
});
const validateRemove = combineValidators({
  value: isRequired("Company Name"),
});
const AddCompany = () => {
  const rootStore = useContext(RootStoreContext);
  const {
    addNewCompanyData,
    openDelModal,
    companies,
    company,
    editCompany,
  } = rootStore.companyStore;

  return (
    <Grid centered>
      <Grid.Column width={15}>
        <Header as="h2" attached="top">
          Register a new Company
        </Header>
        <Segment clearing>
          <FinalForm
            onSubmit={(values: ICompanySubmit) =>
              addNewCompanyData(values).catch((error) => ({
                [FORM_ERROR]: error,
              }))
            }
            validate={validate}
            render={({
              invalid,
              handleSubmit,
              submitting,
              submitError,
              dirtySinceLastSubmit,
              pristine,
            }) => (
              <Form onSubmit={handleSubmit} error loading={submitting}>
                <Form.Group widths="equal">
                  <Field
                    label="Company Name"
                    name="name"
                    placeholder="Company Name"
                    component={TextInput}
                  />
                  <Field
                    label="Tenant Code"
                    name="code"
                    placeholder="Tenant Code"
                    component={TextInput}
                    maxLength={4}
                  />
                  <Field
                    label="Warehouse Code"
                    name="warehousecode"
                    placeholder="Warehouse Code"
                    component={TextInput}
                    maxLength={4}
                  />
                </Form.Group>
                <Form.Group widths="6">
                  <Field
                    label="FTP Name"
                    name="ftpName"
                    placeholder="FTP Name"
                    component={TextInput}
                  />
                  <Field
                    label="FTP Password"
                    name="ftpPassword"
                    placeholder="FTP Password"
                    component={TextInput}
                  />
                </Form.Group>
                <UploadPhoto />

                <br></br>
                <Form.Group widths="equal" style={{ float: "right" }}>
                  {submitError && !dirtySinceLastSubmit && (
                    <RegisterErrorMessage error={submitError} />
                  )}

                  <Button
                    disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                    icon="plus"
                    positive
                    loading={submitting}
                    content="Submit"
                  />
                </Form.Group>
              </Form>
            )}
          />
        </Segment>
      </Grid.Column>

      <Grid.Column width={15}>
        <Header as="h2" attached="top">
          Update Company Details
        </Header>
        <Segment clearing>
          <FinalForm
            onSubmit={(values: ICompany) => {}}
            render={({
              handleSubmit,
              invalid,
              dirtySinceLastSubmit,
              pristine,
              submitting,
            }) => (
              <Form onSubmit={handleSubmit} loading={submitting}>
                <Form.Group widths="equal">
                  <Field
                    label="Company Name"
                    options={companies}
                    name="companyId"
                    componentFlag={"FindCompany"}
                    placeholder="Company Name"
                    component={SelectInput}
                  />
                </Form.Group>
              </Form>
            )}
          />
          <br />

          {company && (
            <div>
              <FinalForm
                onSubmit={(values: ICompanyValues) =>
                  editCompany(values).catch((error) => ({
                    [FORM_ERROR]: error,
                  }))
                }
                initialValues={company}
                validate={validateUpdate}
                render={({
                  invalid,
                  handleSubmit,
                  submitting,
                  submitError,
                  dirtySinceLastSubmit,
                  pristine,
                }) => (
                  <Form onSubmit={handleSubmit} error loading={submitting}>
                    <Form.Group widths="equal">
                      <Field
                        label="Company Name"
                        name="value"
                        placeholder="Company Name"
                        component={TextInput}
                      />
                      <Field
                        label="Tenant Code"
                        name="key"
                        placeholder="Tenant Code"
                        component={TextInput}
                        maxLength={4}
                      />
                      <Field
                        label="Warehouse Code"
                        name="warehousecode"
                        placeholder="Warehouse Code"
                        component={TextInput}
                        maxLength={4}
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <Field
                        label="FTP Name"
                        name="ftpName"
                        placeholder="FTP Name"
                        component={TextInput}
                      />
                      <Field
                        label="FTP Password"
                        name="ftpPassword"
                        placeholder="FTP Password"
                        component={TextInput}
                      />
                    </Form.Group>

                    <br></br>
              
                      {submitError && !dirtySinceLastSubmit && (
                        <RegisterErrorMessage error={submitError} />
                      )}
                   <Form.Group widths="equal" style={{ float: "right" }}>
                      <Button
                        disabled={
                          (invalid && !dirtySinceLastSubmit) || pristine
                        }
                        icon="sync"
                        primary
                        loading={submitting}
                        content="Update"
                      />
                    </Form.Group>
                  </Form>
                )}
              />
            </div>
          )}
        </Segment>
      </Grid.Column>

      <Grid.Column width={15}>
        <Header as="h2" attached="top">
          Remove Company
        </Header>
        <Segment clearing>
          <FinalForm
            onSubmit={(values: ICompany) =>
              openDelModal(values).catch((error) => ({
                [FORM_ERROR]: error,
              }))
            }
            validate={validateRemove}
            render={({
              handleSubmit,
              invalid,
              dirtySinceLastSubmit,
              pristine,
              submitting,
            }) => (
              <Form onSubmit={handleSubmit} loading={submitting}>
                <Form.Group widths="equal">
                  <Field
                    label="Company Name"
                    options={companies}
                    name="value"
                    placeholder="Company Name"
                    component={SelectInput}
                  />
                </Form.Group>

                <Form.Group style={{ float: "right" }}>
                  <Button
                    icon="remove"
                    labelPosition="left"
                    color="red"
                    content="Remove"
                    disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                  />
                </Form.Group>

                <br></br>
              </Form>
            )}
          />
        </Segment>
      </Grid.Column>
    </Grid>
  );
};
export default observer(AddCompany);
