
export interface IOrder {
  orderId?: string; 
  deliveryMethod: string
  A_line_type: string;
  B_tenant_code: string;
  C_ord_type_code: string;
  D_del_date: string;
  E_spec_inst: string;
  F_spec_inst2: string;
  G_dummy2: string;
  H_dummy3: string;
  I_spec_inst3: string;
  J_cust_ref: string;
  K_dummy4: string;
  L_tenant_outlet: string;
  M_outlet_name: string;
  N_delivery_address_1: string;
  O_delivery_address_2: string;
  P_dummy5: string;
  Q_delivery_address_3: string;
  R_dummy6: string;
  S_delivery_town: string;
  T_delivery_address_5: string;
  U_tenant_cust_ref: string;
  V_spec_inst4: string;
  status: boolean;
  ProductList: Array<{}>;
  OrderSender: Object;
}
export const OrderTypeOptions = [
  { key: '10', text: 'DP - Duty Paid', value: 'DP' },
  { key: '50', text: 'UB - Bonded Order', value: 'UB' },
  { key: '55', text: 'UX - Under Bonded Ex. State', value: 'UX' },
  { key: '61', text: 'DE - Duty Free Embassy', value: 'DE' },
];
// list of the delivery methods
export const DeliveryMethodOptions = [
  { key: 'Default', text: 'Masterlink', value: 'DEFAULT' },
  { key: 'Currier', text: 'Courier', value: 'COUR' },
  { key: 'Collection', text: 'Collection', value: 'COLL' },
 
];


// list of the delivery methods
export const DeliveryMethodOptions_BoutiqueWines = [
  { key: 'Default', text: 'Masterlink', value: 'DEFAULT' },
  { key: 'Currier', text: 'Courier', value: 'COUR' },
  { key: 'Collection', text: 'Collection', value: 'COLL' },
  { key: 'BD', text: 'BD Courier', value: 'BD' }, 
  { key: 'RFC', text: 'RFC Courier', value: 'RFC' },
 ];



export const DeliveryMethodOptions_Morgans = [
  { key: 'Default', text: 'Masterlink', value: 'DEFAULT' },
  { key: 'Currier', text: 'Courier', value: 'COUR' },
  { key: 'Collection', text: 'Collection', value: 'COLL' },
  { key: 'BD', text: 'BD Courier', value: 'BD' },
];


// need to load outlet select 
// this is the object i'm going to load the form with data from the order store
export interface IOrderOutlet {
  A_line_type: string;
  C_ord_type_code: string;
  deliveryMethod: string
  D_del_date: string;
  Special_inst: string;
  J_cust_ref: string;
  L_tenant_outlet: string;
  M_outlet_name: string;
  N_delivery_address_1: string;
  O_delivery_address_2: string;
  Q_delivery_address_3: string;
  S_delivery_town: string;
  T_delivery_address_5: string;
}

interface IOrderProducts {
  orderedProductId: string;
  productCode: string;
  singleUnits: number;
  caseUnits: number;
  name: string;
  ipu: number;
  availableUnits: number; 
  product_Short_code: string

}

interface IOutlet {
  outletId: string;
  address: IAddress;
  name: string;
  outletCode: string;
}

interface IAddress {
  addressId: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  addressLine4: string;
  addressLine5: string;
  outletId: string;
}

interface IOrderSender {
  orderSenderId: string;
  emailAddress: string;
  username: string;
  orderId: string;
}

export interface IDataOrderProfile {
  orderId: string;
  singleUnits: number
  customerReference: string;
  casesOrdered:number
  deliveryPointReference: string;
  orderDate: Date;
  products: Array<IOrderProducts>;
  ordertype: string;
  outlet: IOutlet;
  specialInstruction: string;
  OrderSender: IOrderSender;
  status: Boolean;
  companyKey: string;
  deliveryMethod: string
  product_Short_code: string
}
