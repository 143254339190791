import React, { useContext } from "react";
import { Form, Label, FormFieldProps, Select } from "semantic-ui-react";
import { FieldRenderProps } from "react-final-form";
import { DropdownProps } from "reactstrap";
import { RootStoreContext } from "../../../stores/rootStore";

interface IProps
  extends FieldRenderProps<string, HTMLElement>,
    FormFieldProps {}

const SelectInput: React.FC<IProps> = ({
  input,
  width,
  placeholder,
  required,
  options,
  key,
  loading,
  searchChange,
  label,
  componentFlag,
  meta: { touched, error },
}) => {
  const rootStore = useContext(RootStoreContext);
  const { find, loadUsersForCompany } = rootStore.companyStore;

  const handleChange = (data: DropdownProps) => {
    // need to select the tenant id

    if (componentFlag === "FindCompany") {
      find(data.value as string);
    }
    if (componentFlag === "registeredUsers") {
      loadUsersForCompany(data.value as string);
    }

    input.onChange(data.value);
  };

  return (
    <Form.Field error={touched && !!error} width={width}>
      <label>{label}</label>
      <Select
        onSearchChange={() => searchChange}
        key={key}
        search
        loading={loading}
        required={required}
        placeholder={placeholder}
        value={input.value}
        onChange={(event: any, data: any) => handleChange(data)}
        options={options}
      />

      {touched && error && (
        <Label basic color="red">
          {error}
        </Label>
      )}
    </Form.Field>
  );
};
export default SelectInput;
