import React, { useState, useContext, useEffect } from "react";
import {
  Collapse,
  Container,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from "reactstrap";
import { Link } from "react-router-dom";
import logo from "../../../assets/logo.png";
import { RootStoreContext } from "../../../stores/rootStore";
import { Icon } from "semantic-ui-react";

export default function AuthNavMenu() {
  const [collapsed, setCollapsed] = useState(true);
  const rootStore = useContext(RootStoreContext);

  const { logout } = rootStore.userStore;
  const { user, isLoggedIn } = rootStore.userStore;
  const [location, setLocation] = useState("");
  
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    if (user !== null && user.role === "Admin") setLocation("/dashboard");
    else setLocation("/customer-profile");
  }, [user, setLocation]);

  return (
    <div>
      <Navbar
        className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3"
        light
        style={{ backgroundColor: "#F8F9FA", height: 77 }}
      >
        <Container>
          <NavbarBrand tag={Link} to={isLoggedIn ? location : "/"}>
            <img
              src={logo}
              className="float-right"
              style={{ width: "200px", height: "65px" }}
              alt="OrderIT Logo"
            />
          </NavbarBrand>
          <NavbarToggler onClick={toggleNavbar} className="mr-2" />
          <Collapse
            className="d-sm-inline-flex flex-sm-row-reverse"
            isOpen={collapsed}
            navbar
          >
            <ul className="navbar-nav flex-grow">
              {isLoggedIn ? (
                <NavItem>
                  <Dropdown
                    isOpen={dropdownOpen}
                    toggle={toggle}
                    className="nav-link"
                  >
                    <DropdownToggle
                      style={{ backgroundColor: "#F8F9FA", color: "#000000" }}
                      caret
                    >
                        {user && <Icon name="user circle" />}
                      {user ? user.username : false}
                    </DropdownToggle>
                    <DropdownMenu style={{ backgroundColor: "#F8F9FA" }}>
                      <DropdownItem onClick={logout}>
                        Logout <Icon name="sign-out" size="small" />
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </NavItem>
              ) : (
                false
              )}
            </ul>
          </Collapse>
        </Container>
      </Navbar>
    </div>
  );
}
