import { FORM_ERROR } from "final-form";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Field, Form as FinalForm } from "react-final-form";
import { combineValidators, isRequired, matchesField } from "revalidate";
import { Button, Form, Header, Segment } from "semantic-ui-react";
import { IRegisterModel } from "../../models/registerModel";
import SelectInput from "../../components/layout/form/SelectInput";
import TextInput from "../../components/layout/form/TextInput";
import { RootStoreContext } from "../../stores/rootStore";
import { observer } from "mobx-react-lite";
import LoadingComponent from "../../components/layout/LoadingComponent";
import RegisterErrorMessage from "../../components/layout/RegisterErrorMessage";

//http://revalidate.jeremyfairbank.com/common-validators/matchesField.html
const validate = combineValidators({
  name: isRequired("name"),
  username: isRequired("username"),
  role: isRequired('role'),
  email: isRequired("email"),
  email_confirmation: matchesField(
    "email",
    "email_confirmation"
  )({ message: "Emails do not match" }),
  company: isRequired("company")
});

const RegisterUser = () => {
  const rootStore = useContext(RootStoreContext);
  const { Register, submitting, loadUserRole, userRoles } = rootStore.userStore;
  const { companies, GetCompanies } = rootStore.companyStore;
  const [loadingComp, setLoadingComp] = useState(true)
  const [loadingRoles, setLoadingRoles] = useState(true)


  useEffect(() => {
    GetCompanies().then(() => setLoadingComp(false))
    loadUserRole().then(() => setLoadingRoles(false))
  }, [GetCompanies, loadUserRole]);

  if (submitting) return <LoadingComponent content="Registering user" />;
  return (
<Fragment>
  
   
          <Header as="h2" attached="top">
            Register a New User
          </Header>
          <Segment clearing>
            <FinalForm
              onSubmit={(values: IRegisterModel) =>
                Register(values).catch(error => ({
                  [FORM_ERROR]: error
                }))
              }
              validate={validate}
              render={({
                handleSubmit,
                submitting,
                submitError,
                invalid,
                pristine,
                dirtySinceLastSubmit
              }) => (
                <Form onSubmit={handleSubmit} error>
                  <Form.Group widths="equal">
                    <Field
                      label="Name"
                      name="name"
                      placeholder="Name"
                      component={TextInput}
                    />
                    <Field
                      label="Username"
                      name="username"
                      placeholder="Username"
                      component={TextInput}
                    />
                    <Field
                      label="Email"
                      name="email"
                      placeholder="Email"
                      type="email"
                      component={TextInput}
                    />
                    <Field
                      label="Repeat Email"
                      name="email_confirmation"
                      placeholder="Confirm Email"
                      type="email"
                      component={TextInput}
                    />
                  </Form.Group>

                  <Form.Group widths="equal">
                    <Field
                      label="Company"
                      component={SelectInput}
                      name="company"
                      key={'key'}
                      loading={loadingComp}
                      placeholder="Select a Company"
                      options={companies}
                    />

                    <Field
                    required
                      name="role"
                      key={'role'}
                      loading={loadingRoles}
                      label="Role"
                      placeholder="Select a Role"
                      options={userRoles}
                      component={SelectInput}
                    />
                  </Form.Group>
                  {submitError && !dirtySinceLastSubmit && (
                    <RegisterErrorMessage error={submitError} />
                 )}
                 
                  <Button
                    icon="signup"
                    loading={submitting}
                    disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                    positive
                    content="Register"
                  />
                </Form>
              )}
            />
          </Segment>

    </Fragment>
  );
};

export default observer(RegisterUser);
