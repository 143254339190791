import { observer } from "mobx-react-lite";
import React, { Fragment } from "react";
import { Grid } from "semantic-ui-react";
import RegisteredUsers from "./RegisteredUsers";
import RegisterUser from "./registerUser";
 
interface IProps {
  children: any;
}
const ManageUsers: React.FC = () => {
  return (
    <Fragment>
      <Grid centered>
        <Grid.Row>
          <Grid.Column width="16">
            <RegisterUser />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width="16">
           <RegisteredUsers/>
          </Grid.Column>
        </Grid.Row>
       
      </Grid>
    </Fragment>
  );
};

export default observer(ManageUsers);
