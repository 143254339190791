import React, { useContext, useEffect, useState } from "react";
import { Container } from "reactstrap";
import { OrderTypeOptions, DeliveryMethodOptions, DeliveryMethodOptions_Morgans, DeliveryMethodOptions_BoutiqueWines } from "../../models/order.ts";
import { RootStoreContext } from "../../stores/rootStore";
import { Icon, Dropdown, Button, Grid, Segment } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { Redirect } from "react-router-dom";
import WarningMessage from "../../components/layout/WarningMessage";
import LoadingComponent from "../../components/layout/LoadingComponent";
import UnitsErrorMessage from "../../components/layout/UnitsErrorMessage";
import Profile from "./Profile";
 

function PlaceOrder() {
  const rootStore = useContext(RootStoreContext);
  const {isLoggedIn, user } = rootStore.userStore;
  const {
    outletsList,
    resetOutletsList,
    productsList,
    SaveOrderDetails,
    HandleOutletsSearch,
    HandleProductsSearch,
    UpdateNumCases,
    UpdateSingleUnits,
    orderTableList,
    AddProductToTable,
    HandleRemoveProduct,
    SubmitOrderDetails,
    AddSelectedOutlet,
    ResetOrder,
    TC_checked,
    caseUnitsError,
    singleUnitsError,
    setOrderType,
    orderType,
    defaultDeliveryMethod,
    boundOrderFlag,
    order,isMethodChangedReadOnly,
    MyReferenceChange,
    submittingData,
    DeliveryPointRefChange,
    handleSpecialInstructionsChange,
    specialInstructions,
    openCloseOutletProductWindow,
    outletOpenFlag,
    productOpenFlag,
    ResetOrderObject,
    setDeliveryMethod,
    resetDelMethodChange,totalCases, totalSingleUnits
  } = rootStore.orderStore;


const onChange = (event, result) => {
    const { value, name } = result || event.target;
  
    resetOutletsList();
    ResetOrderObject();
    setDeliveryMethod("DEFAULT");
    switch (name) {
      case "C_ord_type_code":
       
        resetDelMethodChange();
        setOrderType(value);
        break;
      case "delMethod":
        resetDelMethodChange();
        setDeliveryMethod(value);
        break;
      default:
        break;
    }
  };

  const handleMyRefChange = (event) => MyReferenceChange(event.target.value);

  const handleSpecialInstChange = (event) =>  handleSpecialInstructionsChange(event.target.value);

  const handleDelRefChange = (event) => DeliveryPointRefChange(event.target.value);

  const handleOnChange_product = (e, data) => AddProductToTable(data.value);

  const handleOnChange_outlet = (e, data) => AddSelectedOutlet(data.value);

  const onSubmit = (event) => {
    // setOrderType('')
    event.preventDefault();
    SubmitOrderDetails();
    resetOutletsList();
  };

  const handleClearBtn = (event) => {
    event.preventDefault();
    resetOutletsList();
    ResetOrder();
  };

  const handleSaveBtn = (event) => {
    event.preventDefault();
    SaveOrderDetails();
    resetOutletsList();
  };

  const numbCasesChange = (e, index) => {
    UpdateNumCases(e.target.value, index);
  };

  const singleUnitsChange = (e, index) => {
    UpdateSingleUnits(e.target.value, index);
  };

  const handleNextOption = (e, optionFrag) => {
    e.preventDefault();
    openCloseOutletProductWindow(optionFrag);
  };



  const [deliveryMethods, setSetDeliveryMethods] = useState([])


  useEffect(() => {
  
if(isLoggedIn && user.company.key === 'MORG')
setSetDeliveryMethods(DeliveryMethodOptions_Morgans)
else if(isLoggedIn && user.company.key === 'BOUT')
setSetDeliveryMethods(DeliveryMethodOptions_BoutiqueWines)
else
setSetDeliveryMethods(DeliveryMethodOptions)
 
  }, [isLoggedIn, user, setSetDeliveryMethods,DeliveryMethodOptions_Morgans,DeliveryMethodOptions])

  if (!isLoggedIn) return <Redirect to="/" />;

  if (submittingData)
    return <LoadingComponent content={"Submitting order..."} />;
  return (
    <Profile>
      <Container>
        {!productOpenFlag && (
          <div className="row">
            <div className="col">
              <label>Select the Order Type</label>
              <Dropdown
                name="C_ord_type_code"
                className="form-control"
                placeholder="Select an Order Type"
                fluid
                lazyLoad
                selection
                value={orderType || ""}
                required={true}
                onChange={onChange}
                options={OrderTypeOptions}
              />
            </div>
            <div className="col">
              <label>Select Delivery Method</label>
              <Dropdown
                name="delMethod"
                className="form-control"
                placeholder="Select Delivery Method"
                fluid
                disabled={isMethodChangedReadOnly}
                value={defaultDeliveryMethod || ""}
                selection
                lazyLoad
                required={true}
                onChange={onChange}
                options={deliveryMethods}
              />
            </div>
          </div>
        )}

        {outletOpenFlag ? (
          <Segment>
            <label>Select a Customer Name</label>
            <Dropdown
              placeholder="Select Outlet..."
              fluid
              selectOnBlur={false}
              icon="search"
              search
              lazyLoad
              selection
              closeOnEscape={true}
              value={""}
              onChange={handleOnChange_outlet}
              closeOnChange={true}
              text={
                order
                  ? order.M_outlet_name + " - " + order.N_delivery_address_1
                  : "Select Outlet..."
              }
              onSearchChange={(event) =>
                HandleOutletsSearch(event.target.value.replace(/[^\w\s]/gi, ""))
              }
              options={outletsList}
            />
          </Segment>
        ) : null}

        <form
          onSubmit={onSubmit}
          onKeyPress={(e) => {
            e.key === "Enter" && e.preventDefault();
          }}
          method="post"
        >
          <br />

          {outletOpenFlag
            ? order && (
                <div id="outletDetails">
                  <Grid centered>
                    <Grid.Column width={16}>
                      <Segment className="ui.segment.place.order">
                        <label>My Reference</label>
                        <input
                          type="text"
                          maxLength="15"
                          placeholder="My Reference"
                          required
                          className={
                            !order.J_cust_ref
                              ? "form-control error-class"
                              : "form-control "
                          }
                          name="J_cust_ref"
                          value={order.J_cust_ref || ""}
                          onChange={handleMyRefChange}
                        />

                        {!order.J_cust_ref && (
                          <p style={{ color: "red" }}>
                            <i>This field is required!</i>
                          </p>
                        )}

                        <label>Delivery Point Reference</label>
                        <input
                          type="text"
                          maxLength="15"
                          className={
                            !order.U_tenant_cust_ref
                              ? "form-control error-class"
                              : "form-control"
                          }
                          name="U_tenant_cust_ref"
                          required
                          onChange={handleDelRefChange}
                          placeholder="Delivery Point Reference"
                          value={order.U_tenant_cust_ref || ""}
                        />

                        {!order.U_tenant_cust_ref && (
                          <p style={{ color: "red" }}>
                            <i>This field is required!</i>
                          </p>
                        )}

                        <label>Special Instructions </label>
                        <textarea
                          id="E_spec_inst"
                          className="form-control"
                          maxLength="600"
                          rows="5"
                          name="E_spec_inst"
                          placeholder="Special Instructions (max 600 characters)"
                          onChange={handleSpecialInstChange}
                          value={specialInstructions || ""}
                        ></textarea>
                        <div className="col">
                          <p style={{ color: "red" }}>
                            <i>
                              Please note that special instructions must be
                              agreed in advance with the operations team!
                            </i>
                          </p>
                        </div>
                      </Segment>
                    </Grid.Column>
                  </Grid>

                  <br />
                  <br />

                  <Button
                    content="Add Products"
                    disabled={!(order.J_cust_ref && order.U_tenant_cust_ref)}
                    onClick={(e) => handleNextOption(e, "outletFlag")}
                    positive
                    icon="plus"
                    labelPosition="right"
                  />
                </div>
              )
            : null}

          {/* search product  */}

          {productOpenFlag && (
            <div>
              <label>Add Product </label>

              {orderTableList.length > 0 && (
                <div id="productDetails">
                  <table className="table order-table" id="productTable">
                    <thead>
                      <tr>
                        <th width="12%">Our Code</th>
                        <th>Warehouse Code</th>
                        <th width="35%">Name</th>
                        <th>Units per Case</th>
                        <th>Last Known Stock</th>
                        <th>Number of Cases</th>
                        <th colSpan="2">Single Units</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orderTableList.map((ol, key) => (
                        <tr key={key}>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              readOnly
                              value={ol.product_Short_code}
                              maxLength="30"
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              readOnly
                              value={ol.product_Code}
                              maxLength="30"
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              readOnly
                              value={ol.product_Name}
                            />
                          </td>
                          <td className="form-number-input">
                            <input
                              type="text"
                              className="form-control"
                              readOnly
                              value={ol.ipu}
                            />
                          </td>
                          <td className="form-number-input">
                            <input
                              type="text"
                              className="form-control"
                              readOnly
                              value={ol.available_Cases}
                            />
                          </td>
                          <td className="form-number-input">
                            <input
                              id={ol.product_Code.toString()}
                              name="NumberOfCases"
                              placeholder="Cases"
                              value={ol.numberOfCases || ""}
                              onChange={(e) => numbCasesChange(e, key)}
                              className={
                                ol.casesErrorFlag
                                  ? "form-control error-class"
                                  : "form-control"
                              }
                              type="number"
                            />
                          </td>
                          <td className="form-number-input">
                            <input
                              name="SinglesUnits"
                              readOnly={boundOrderFlag}
                              placeholder="Singles"
                              value={ol.singleUnits === 0 ? "" : ol.singleUnits}
                              onChange={(e) => singleUnitsChange(e, key)}
                              className={
                                ol.singlesErrorFlag
                                  ? "form-control error-class"
                                  : "form-control"
                              }
                              type="number"
                            />
                          </td>

                          <td>
                            <button
                              type="button"
                              onClick={() => HandleRemoveProduct(ol)}
                              className="btn btn-danger"
                            >
                              Remove
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div>
                    <label>
                      Number of Products:
                      <strong>{orderTableList.length}</strong>
                    </label>
                    <br />
                    <label>
                      Total number of cases in this Order:
                      <strong>{totalCases}</strong>
                    </label>
                    <br />
                    <label>
                      Total number of single units in this Order:
                      <strong>{totalSingleUnits}</strong>
                    </label>
                  </div>
                </div>
              )}

              <br />
              {(singleUnitsError || caseUnitsError) &&
              orderTableList.length > 0 ? (
                <UnitsErrorMessage
                  text={
                    "Please note that the number of units entered exceeds the known stock!"
                  }
                />
              ) : null}

              <Dropdown
                placeholder="Select a Product..."
                fluid
                selectOnBlur={false}
                selectOnNavigation={false}
                search
                icon="search"
                selection
                closeOnEscape
                value={""}
                onChange={handleOnChange_product}
                closeOnChange
                onSearchChange={(event) =>
                  HandleProductsSearch(
                    event.target.value.replace(/[^\w\s]/gi, "")
                  )
                }
                options={productsList}
              />
              <br />
              {orderTableList.length > 0 ? <WarningMessage /> : null}
              <br />
              <br />
              <Button
                content="Back"
                onClick={(e) => handleNextOption(e, "productFlag")}
                positive
                icon="arrow left"
                labelPosition="right"
              />
            </div>
          )}
          <br />
          <hr />
          {productOpenFlag && (
            <div>
              <div className="form-check">
                <input
                  checked={TC_checked || null}
                  type="checkbox"
                  required
                  className="form-check-input"
                  id="termsAndConditions"
                />

                <label className="form-check-label">
                  I've read and agree to the Terms & Conditions of Trade for
                  Masterlink{" "}
                </label>
              </div>
              <br />

              <div style={{ position: "absolute", paddingRight: 5 }}>
                <button
                  style={{ marginRight: 5 }}
                  className="btn btn-success"
                  disabled={!(orderTableList.length > 0 && order)}
                >
                  <Icon name="save" size="small" />
                  Submit Order
                </button>

                <button
                  style={{ marginRight: 5 }}
                  className="btn btn-primary"
                  disabled={!order}
                  onClick={handleSaveBtn}
                >
                  <Icon name="archive" size="small" />
                  Save
                </button>
                <button
                  style={{ marginRight: 5 }}
                  className="btn btn-danger"
                  disabled={!(orderTableList.length > 0 || order)}
                  onClick={handleClearBtn}
                >
                  <Icon name="trash" size="small" />
                  Delete
                </button>
              </div>
            </div>
          )}

          <br />
          <br />
        </form>
        <br />
        <br />
        <br />
      </Container>
    </Profile>
  );
}
 
export default observer(PlaceOrder);
