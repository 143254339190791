import React, { Fragment, useEffect, useContext, useCallback } from "react";
import { Route, Switch } from "react-router";
import { ToastContainer } from "react-toastify";
import "./styles/custom.css";
import "./styles/myStyle.css";
import Login from "./authentication/Login";
import Footer from "./components/layout/Footer";
import Dashboard from "./views/admin/dashboard";
import { RootStoreContext } from "./stores/rootStore";
import ResetPassword from "./views/resetPassword";
import { observer } from "mobx-react-lite";
import LoadingComponent from "./components/layout/LoadingComponent";
import AuthenticationRoute from "./authentication/AuthenticationRoute";
import OrderDetailsModal from "./components/customer/modals/OrderDetailsModal";
import DeleteConfirmationModal from "./components/customer/modals/DeleteConfirmationModal";
import DeleteCompanyConfirmationModal from './components/customer/modals/DeleteCompanyConfirmationModal'
import PageNotfound from "./views/PageNotFound";
import ForgotPassword from "./authentication/ForgotPassword";
import PlaceOrder from "./views/customer/PlaceOrder";
import ManageOrders from "./views/customer/ManageOrders";
import DeleteUserConfirmationModal from "./components/customer/modals/DeleteUserConfirmationModal";

const App = () => {



  const rootStore = useContext(RootStoreContext);
  const { setAppLoaded, token, appLoaded } = rootStore.commonStore;
  const { getUser } = rootStore.userStore;

  const reloadPage = useCallback(() => {
    // Refresh Rate is how often you want to refresh the page
    // bassed off the user inactivity.
    var refresh_rate = 3580; //<-- In seconds, change to your needs
    var last_user_action = 0;
    var has_focus = false;
    var lost_focus_count = 0;
    var focus_margin = 10;
    // Reset the Timer on users last action
    function reset() {
      last_user_action = 0;
    }

    // Count Down that executes ever second
    setInterval(function () {
      last_user_action++;
      refreshCheck();
    }, 1000);

    // The code that checks if the window needs to reload
    function refreshCheck() {
    
      if (
        (last_user_action >= refresh_rate &&
          !has_focus &&
          document.readyState === "complete") ||
        lost_focus_count > focus_margin
      ) {
        window.location.reload(); // If this is called no reset is needed
        reset(); // We want to reset just to make sure the location reload is not called.
      }
    }

    window.addEventListener("click", reset, false);
    window.addEventListener("mousemove", reset, false);
    window.addEventListener("keypress", reset, false);
  }, []);






  useEffect(() => {
    if (token) {
      getUser().finally(() => setAppLoaded());
    } else {
      setAppLoaded();
    }

    reloadPage();
  }, [getUser, setAppLoaded, token, reloadPage]);

  if (!appLoaded) return <LoadingComponent content="Loading BOPS..." />;
  return (
    <Fragment>
      <ToastContainer position="bottom-right" />
      <OrderDetailsModal />
      <DeleteConfirmationModal />
      <DeleteCompanyConfirmationModal />
      <DeleteUserConfirmationModal/>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route
          exact
          path="/resetPassword/:passToken_block1/:passToken_block2/:passToken_block3/:username/:email"
          component={ResetPassword}
        />
        <AuthenticationRoute exact path="/dashboard" component={Dashboard} />
        <AuthenticationRoute
          exact
          path="/customer-profile"
          component={PlaceOrder}
        />
        <AuthenticationRoute exact path="/my-orders" component={ManageOrders} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/*" component={PageNotfound} />
      </Switch>
      <Footer />
    </Fragment>
  );
};
export default observer(App);
