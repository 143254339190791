import React, { Fragment, useContext } from "react";
import { Modal, Button, Icon, Message, Segment } from "semantic-ui-react";
import { RootStoreContext } from "../../../stores/rootStore";
import { observer } from "mobx-react-lite";

const DeleteUserConfirmationModal = () => {
  const rooStore = useContext(RootStoreContext);
  const { delUserModal, CloseDelModal, RemoveUser, deletingUserLoading } = rooStore.userStore;

  return (
    <Modal
      open={delUserModal}
      onClose={CloseDelModal}
      closeIcon
      style={{
        height: 200,
        width: 550,
        position: "fixed",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        margin: "auto",
      }}
    >
      <Modal.Header>Delete Confirmation</Modal.Header>
      <Segment loading={deletingUserLoading}>
      <Modal.Content>
        <p>Are you sure you want to remove this User?</p>
 
      </Modal.Content>
      </Segment>
      <Modal.Actions>
        <Button color="red" onClick={CloseDelModal}>
          <Icon name="remove" /> No
        </Button>
        <Button loading={deletingUserLoading} color="green" onClick={() => RemoveUser()}>
          <Icon name="checkmark" /> Yes
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default observer(DeleteUserConfirmationModal);
